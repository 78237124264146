<script setup lang="ts">
import { DropdownMenuGroup, type DropdownMenuGroupProps } from 'radix-vue';

const props = defineProps<DropdownMenuGroupProps>();
</script>

<template>
    <DropdownMenuGroup class="space-y-1" v-bind="props">
        <slot />
    </DropdownMenuGroup>
</template>
